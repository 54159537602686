import * as actionType from '../constants/actionTypes'

const persistedTheme = localStorage.getItem('theme') || 'light'

const themeReducer = (
  // eslint-disable-next-line default-param-last
  state = {theme: persistedTheme, isActive: persistedTheme === 'dark'},
  action
) => {
  switch (action.type) {
    case actionType.TOGGLE_THEME:
      return {
        ...state,
        theme: action.theme === 'dark' ? 'light' : 'dark',
        isActive: action.isActive
      }
    default:
      return state
  }
}

export default themeReducer
