export const LOGOUT = 'LOGOUT'
export const SIGNIN = 'SIGNIN'
export const REQUEST_ACCESS = 'REQUEST_ACCESS'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const VERIFY_USER = 'VERIFY_USER'
export const LOG_OUT = 'LOG_OUT'
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'
export const SET_ERROR = 'SET_ERROR'
export const TOGGLE_THEME = 'TOGGLE_THEME'
export const LOADING = 'LOADING'
