import React, { Suspense, lazy, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import './index.css'
import { EventType } from '@azure/msal-browser'
import { BrowserRouter as Router } from 'react-router-dom'
import reducers from './store/reducers'
import Spinner from '../assets/spinner.svg'
import { msalInstance } from './store/api/apiUtils.js'

// Lazy load App component
const App = lazy(() => import('./App.jsx'))

// Create Redux store
const store = createStore(
  reducers,
  process.env.NODE_ENV === 'production'
    ? compose(applyMiddleware(thunk))
    : composeWithDevTools(applyMiddleware(thunk))
)

// Component to manage the app's initialization state
function AppInitializer({ children }) {
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    async function initializeMsal() {
      try {
        await msalInstance.initialize()

        // Default to using the first account if no account is active on page load
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
          msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
        }

        // Optional - This will update account state if a user signs in from another tab or window
        msalInstance.enableAccountStorageEvents()

        // Listen for sign-in event and set active account
        msalInstance.addEventCallback((event) => {
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const { account } = event.payload
            msalInstance.setActiveAccount(account)
          }
        })

        setIsInitialized(true)
      } catch (error) {
        console.error('Failed to initialize msalInstance:', error)
      }
    }

    initializeMsal()
  }, [])

  return isInitialized ? children : null
}

// Render the app
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <AppInitializer>
        <Suspense
          fallback={
            <div
              className="spinner"
              style={{
                background:
                  localStorage.getItem('theme') === 'dark' ? '#1e1e1e' : '#fff',
              }}
            >
              <Spinner
                fill="#0ac1ba"
                style={{
                  margin: 'auto',
                  background:
                    localStorage.getItem('theme') === 'dark' ? '#1e1e1e' : '#fff',
                  display: 'flex',
                }}
              />
            </div>
          }
        >
          <Router>
            <App instance={msalInstance} />
          </Router>
        </Suspense>
      </AppInitializer>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)
