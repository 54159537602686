/* eslint-disable default-param-last */
import {
  SIGNIN,
  REQUEST_ACCESS,
  RESET_PASSWORD,
  VERIFY_USER,
  LOG_OUT,
  NOT_AUTHENTICATED,
  SET_ERROR,
  LOADING
} from '../constants/actionTypes.js'

const authReducer = (
  state = {isAuthenticated: false, isLoading: false, error: null},
  action
) => {
  switch (action.type) {
    case SIGNIN:
      return {
        ...state,
        isAuthenticated: action.verified,
        role: action?.role,
        menu: action?.menu,
        theme: action?.theme,
        isLoading: action.isLoading,
        email: action.email
      }
    case LOADING:
      return {...state, isLoading: action.isLoading}
    case REQUEST_ACCESS:
      return {...state, ...action.data}
    case RESET_PASSWORD:
      return {...state, ...action.data}
    case VERIFY_USER:
      return {
        ...state,
        isAuthenticated: action.verified,
        role: action?.role,
        menu: action?.menu,
        theme: action?.theme,
        isLoading: action.isLoading,
        email: action.email
      }
    case LOG_OUT:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        isLoading: action.isLoading
      }
    case SET_ERROR:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        isLoading: action.isLoading,
        error: action.error
      }
    case NOT_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.verified,
        isLoading: action.isLoading,
        email: action.email
      }
    default:
      return state
  }
}

export default authReducer
