import axios from 'axios'
import {
  PublicClientApplication,
  BrowserAuthError,
  InteractionRequiredAuthError
} from '@azure/msal-browser'
import {LOG_OUT} from '../constants/actionTypes.js'
import {msalConfig, loginRequest} from '../../authConfig.js'

// Create a new instance of PublicClientApplication
export const msalInstance = new PublicClientApplication(msalConfig)

// Function to clear user authentication data and perform logout
export const logoutUser = (instance, dispatch) => {
  dispatch({type: LOG_OUT, isLoading: false, isAuthenticated: false})
  instance.logoutRedirect({account: instance.getActiveAccount()})
}

// Function to get the access token using MSAL
export const getAccessToken = (instance) => {
  const accounts = instance?.getAllAccounts()
  if (accounts.length > 0) {
    return new Promise((resolve, reject) => {
      instance
        .acquireTokenSilent({
          loginRequest,
          account: accounts[0]
        })
        .then((response) => {
          // Access token is available in the response
          const {accessToken} = response
          resolve(accessToken)
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .loginRedirect(loginRequest)
              .then((response) => {
                // Access token is available in the response
                const {accessToken} = response
                resolve(accessToken)
              })
              .catch((err) => {
                if (
                  err instanceof BrowserAuthError &&
                  err.errorCode === 'user_cancelled'
                ) {
                  logoutUser(instance) // Logout the user and redirect to the login page
                  reject(
                    new Error('Sign-in process was cancelled by the user.')
                  )
                }
              })
          } else if (
            error instanceof BrowserAuthError &&
            error.errorCode === 'user_cancelled'
          ) {
            logoutUser(instance) // Logout the user and redirect to the login page
            reject(new Error('Sign-in process was cancelled by the user.'))
          } else {
            // Handle any other errors that occurred while acquiring the token
            console.log('Error acquiring token:', error)
            reject(error)
          }
        })
    })
  }
  return console.log('No user accounts available.')
}

// Create a new instance of Axios with the baseURL and request interceptor for MSAL
export const createAPI = (instance) => {
  const baseUrl = '' // Provide your API's base URL here
  const API = axios.create({baseUrl})

  // Track whether the refresh token has been renewed
  let isRefreshed = false

  API.interceptors.request.use(async (req) => {
    try {
      const token = await getAccessToken(instance)

      if (token) {
        req.headers.Authorization = `Bearer ${token}`
      }

      return req
    } catch (error) {
      console.log(error)

      if (!isRefreshed) {
        // Attempt to refresh the token only once
        isRefreshed = true

        try {
          // Acquire a new access token using the refresh token
          const newToken = await instance.acquireTokenSilent({
            account: instance.getAllAccounts()[0],
            scopes: loginRequest.scopes
          })

          // Update the request header with the new access token
          req.headers.Authorization = `Bearer ${newToken.accessToken}`

          // Retry the request with the new access token
          return axios(req)
        } catch (refreshError) {
          console.log('Error refreshing token:', refreshError)
        }
      }

      // If token refresh fails or the request still fails, handle accordingly
      return Promise.reject(error)
    }
  })

  return API
}

export const API = createAPI(msalInstance)

export const verifyUser = async (instance /* dispatch */) => {
  try {
    const {data} = await API.get('/api/v1/login')
    return data
  } catch (error) {
    console.log(error)
    // logoutUser(instance, dispatch)
    await localStorage.clear()
    instance.loginRedirect(loginRequest)
    return {verified: false, status: 401}
  }
}
